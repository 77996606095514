module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-163663407-1"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Liquefy","short_name":"Liquefy","description":"Liquefy","start_url":"/","background_color":"#fff","theme_color":"#005eff","display":"standalone","cache_busting_mode":"none","icons":[{"src":"/img/favicon/icon_64.png","sizes":"64x64","type":"image/png"},{"src":"/img/favicon/icon_120.png","sizes":"120x120","type":"image/png"},{"src":"/img/favicon/icon_144.png","sizes":"144x144","type":"image/png"},{"src":"/img/favicon/icon_152.png","sizes":"152x152","type":"image/png"},{"src":"/img/favicon/icon_192.png","sizes":"192x192","type":"image/png"},{"src":"/img/favicon/icon_384.png","sizes":"384x384","type":"image/png"},{"src":"/img/favicon/icon_512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Gilroy"],"urls":["/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/home/runner/work/liquefy-gatsby-site/liquefy-gatsby-site/src/cms/cms.js"},
    }]
